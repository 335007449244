.manager-list {
  border-bottom: none;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
.links {
  color: white;
  border: none;
}
