.table_text {
  font-size: 15px;
  font-weight: 500;
}

.table_heading {
  background-color: rgb(210, 234, 242) !important;
  color: grey;
  font-weight: 600;
  font-size: 16px;
}
.form_text {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.more_img {
  height: 20px;
  width: 20px;
}

.btn_colour {
  background-color: #4a85f6;
  color: white;
}

.btn_colour:hover {
  background-color: #4a85f6;
  color: #fff;
}

.cancel_img {
  height: 30px;
  width: 30px;
  margin-top: 10px;
}
.addLinks {
  color: white !important;
}

.table-modal-list {
  border-bottom: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}

.confirmbtn {
  width: 100%;
  background-color: #106f26;
  color: white;
  margin-bottom: 10 !important;
  border: none;
}

.confirmbtn:hover,
.confirmbtn:focus,
.confirmbtn:active {
  background-color: #106f26;
  color: white;
}

.cancelBtn {
  width: 100%;
  background-color: #edfff1 !important;
  color: #106f26;

  border: none;
  margin-top: 20px;
}

.cancelBtn:hover,
.cancelBtn:focus,
.cancelBtn:active {
  background-color: #edfff1 !important;
  color: #106f26;
}

.outletTex {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
}

.outletTitle {
  text-align: center;
  font-size: 25px !important;
  font-weight: bold;

  margin-bottom: 20px;
}

.check-circle {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
  object-fit: contain;
}

.closeConfirmBtn {
  width: 100%;
  background-color: #f06a6b;
  color: white;
  margin-bottom: 10 !important;
  border: none;
}

.closeConfirmBtn:hover,
.closeConfirmBtn:focus,
.closeConfirmBtn:active {
  background-color: #f06a6b;
  color: white;
}

.closecancelBtn {
  width: 100%;
  background-color: #ffeae8 !important;
  color: #f06a6b;

  border: none;
  margin-top: 20px;
}

.closecancelBtn:hover,
.closecancelBtn:focus,
.closecancelBtn:active {
  background-color: #ffeae8 !important;
  color: #f06a6b;
}

.Shiftcard {
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
}

@media print {
  /* Apply styles for thermal paper */
  @page {
    size: 80mm 80mm; /* Set the paper size to match thermal paper */
  }

  body {
    width: 80mm; /* Set the content width to match thermal paper */
    margin: 0; /* Remove default margins */
    padding: 5mm; /* Adjust padding as needed */
  }

  /* Adjust font sizes and styles for better readability */
  p {
    font-size: 8pt; /* Adjust font size as needed */
  }
}

.page-break {
  page-break-after: always;
}
