.layout-container {
  display: flex !important;
  /* background-color: var(--neutral-20, #f5f5f5); */
}

.content {
  flex: 1 !important;
  padding: 20px;
}

.navbar-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.logo-container {
  margin-right: 10px !important;
}

.logo-image {
  height: 50px;
  width: 150px;
  object-fit: cover;
}
.onlylogo-image {
  height: 50px;
  width: 30px;
  object-fit: contain;
  border: none;
  margin-left: 15px;
}

.pro-sidebar > .pro-sidebar-inner {
  background: white;
}
.navbar-toggle {
  background-color: white;
  border: none;
}
.links {
  align-items: center;
}

.navbar-icons {
  font-size: 20px;
  color: black;
  align-self: center;
}

.navbar-menuitem {
  color: black;
  font-weight: 600;
  margin-top: 15px;
  padding-left: 10px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background: white;
  margin-top: 0px;
}

.pro-sidebar .pro-menu .pro-menu-item {
  height: 40px;
}

.custom-submenu {
  position: absolute;
  z-index: 1000;
}

.rs-sidenav-default {
  height: 630px !important;
  /* background-color: white !important; */
  color: black;
}

.links {
  color: black !important;
  border: none;
  text-decoration: none !important;
  font-size: 15px;
  font-weight: 500;
}
