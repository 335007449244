.thermal-bill {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 20px auto;
  width: 100% !important;
}

.thermal-bill h1 {
  margin-bottom: 20px;
}

.thermalBill-font-large {
  font-size: 15px;
}

.thermalBill-font-small {
  font-size: 12px;
}

.bill-details {
  text-align: left;
}

.date-time-bill-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bill-number {
  margin-left: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* table,
th,
td {
  border: 1px solid #000;
}

th,
td {
  padding: 8px;
}

.totals p {
  text-align: left;
}

.terms h2 {
  margin-top: 20px;
}

.terms p {
  text-align: left;
  margin-bottom: 10px;
} */

/* .terms p {
  text-align: right;
  margin-bottom: 10px;
} */
.logo-image {
  display: block;
  margin: 0 auto; /* Horizontally center the image */
  vertical-align: middle;
  margin-bottom: 20px; /* Vertically center the image */
}

.BillPrintFont {
  font-size: 15px;
  font-weight: bold;
}

.BillPrintFontAmount {
  font-size: 18px;
  font-weight: bold;
  width: 300px;
  text-align: right;
}

.qr-code-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
@media screen and (min-width: 220px) and (max-width: 500px) {
  .thermalBill-font-large {
    /* Your styles for widths between 220px and 350px */
    /* For example: */
    font-size: 10px;
  }
  .BillPrintFont {
    font-size: 10px;
  }
  .thermalBill-font-small {
    font-size: 8px;
  }

  .qr-code-image {
    height: 80%;
    width: 80%;
    object-fit: contain;
  }
}
